import { Shell } from "../components/shell"


export const Industries = () => {

    return (
        <Shell>
            <div className="flex flex-col items-center">
                <h1 className="mt-6 text-4xl font-bold tracking-tight theme-text sm:text-6xl py-4 sm:py-8">Coming Soon....</h1>
                <div className="flex w-full items-center justify-center mx-auto w-96 sm:w-1/2">
                    <img src="coming_soon.svg" />
                </div >

            </div>
        </Shell>
    )
}