import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

//   {
//     path: "/",
//     element: <Home />,
//   },
//   {
//     path: "/salesforce",
//     element: <Salesforce />,
//   },
//   {
//     path: "/servicenow",
//     element: <ServiceNow />,
//   },
//   {
//     path: "/erp",
//     element: <ERP />,
//   },
//   {
//     path: "/ai",
//     element: <AI />,
//   },
//   {
//     path: "/ml",
//     element: <ML />,
//   },
//   {
//     path: "/ds",
//     element: <DataScience />,
//   },
//   {
//     path: "/da",
//     element: <DataAnalytics />,
//   },
//   {
//     path: "/cs",
//     element: <CloudService />,
//   },
//   {
//     path: "/bigdata",
//     element: <BigData />,
//   },
//   {
//     path: "/team",
//     element: <TeamDetails />,
//   },
//   {
//     path: "/about",
//     element: <AboutUs />,
//   },
//   {
//     path: "/contact",
//     element: <Contact />,
//   },
// ]);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
