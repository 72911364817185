import React from "react";
import { Shell } from "../components/shell";
import { ContactUs } from "../components/contact-us";

export const Contact = () => {

    return (
        <div>
            <Shell>
                <ContactUs />
            </Shell>
        </div>
    )
}
