import React, { useEffect } from 'react';
import './App.css';
//@ts-ignore
import WOW from 'wowjs';
import { Home } from './components/home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AboutUs } from './pages/about-us';
import { Contact } from './pages/contact-us';
import { RDServices } from './pages/rd-services';
import { TeamDetails } from './pages/team-details';
import { RDSolutions } from './pages/rd-solutions';
import { Industries } from './pages/industries';


function App() {

    useEffect(() => {
        var wow = new WOW.WOW(
            {
                boxClass: 'wow',      // animated element css class (default is wow)
                animateClass: 'animated', // animation css class (default is animated)
                offset: 0,          // distance to the element when triggering the animation (default is 0)
                mobile: true,       // trigger animations on mobile devices (default is true)
                live: true,       // act on asynchronously loaded content (default is true)
                callback: function () {
                    // the callback is fired every time an animation is started
                    // the argument that is passed in is the DOM node being animated
                },
                scrollContainer: null,    // optional scroll container selector, otherwise use window,
                resetAnimation: true,     // reset animation on end (default is true)
            }
        );
        wow.init();
    }, []);


    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path={"/"} element={<Home />} />
                    <Route path={"/team"} element={<TeamDetails />} />
                    <Route path={"/about"} element={<AboutUs />} />
                    <Route path={"/contact"} element={<Contact />} />

                    <Route path={"/services/:key"} element={<RDServices />} />
                    <Route path={"/solutions/:key"} element={<RDSolutions />} />
                    <Route path={"/404"} element={<Industries />} />

                </Routes>
            </BrowserRouter>
        </>
    )
}

export default App;
