
import ai from "./../../resources/ai_01.svg"
import ml from "./../../resources/ml.jpg"
import ds from "./../../resources/ds.svg"
import da from "./../../resources/da.svg"
import cs from "./../../resources/cs.svg"
import bd from "./../../resources/bd.svg"
import sc from "./../../resources/sc.svg"

export const services_list = [
    {
        id: "ai",
        title: 'Artificial Intelligence (AI)',
        description: [
            "Unleash the potential of our AI service, a transformative solution that empowers your organization like never before. With state-of-the-art Artificial Intelligence, you'll automate tasks, gain invaluable insights, and drive exponential growth. Our AI encompasses machine learning, natural language processing, and computer vision, providing an unparalleled advantage in the realm of innovation.",
            "Flexibility is at the core of our AI service. Tailor it to suit your specific needs with its scalable, secure, and customizable features. From automating customer service to optimizing supply chain management and analyzing vast datasets, our AI adapts effortlessly to your requirements, propelling your business forward.",
            "Harness the wisdom of AI-driven decision-making and operational efficiency. Discover hidden patterns and trends that can be game-changers for your success. With informed choices, cost savings, and optimized operations, you'll stay ahead in today's dynamic digital landscape. Moreover, elevate customer engagement to new heights with advanced natural language processing and computer vision capabilities. Free your team from repetitive tasks and embrace innovative ways to connect with your audience.",
                   ],
        img_path: `${ai}`
    },
    {
        id: "ml",
        title: "Machine Learning (ML)",
        description: [
            "Unleash growth with our ML service! Leverage data and automation to drive innovation. Build intelligent apps, automate tasks, and gain insights. Scalable, secure, customizable – tailored to your goals. Optimize decisions, reduce costs, and boost efficiency. Deploy chatbots, recommendation systems, and more. Stay ahead in the dynamic digital landscape. Transform with our ML service today!",
            "Revolutionize your organization with our ML service – a gateway to unprecedented growth and success. Embrace the cutting-edge world of data-driven solutions, where intelligent applications and models pave the way for seamless decision-making and task automation. With our service's scalability, security, and customizability, you have the freedom to mold it precisely to your unique needs, be it predictive modeling, image recognition, or optimizing supply chains.",
            "Gain a decisive advantage in your industry as our ML service delves deep into vast datasets, uncovering hidden patterns and invaluable insights that drive your business towards excellence. Empower your team to focus on strategic initiatives as routine tasks are effortlessly automated by our advanced capabilities. Embrace a future of innovation and efficiency, and unlock the full potential of your organization with our ML service at your side. The digital landscape is ever-changing, and with our comprehensive solution, you can confidently stay ahead and lead your industry towards a brighter tomorrow.",
                   ],
        img_path: `${ml}`
    },
    {
        id: "ds",
        title: "Data Science",
        description: [
            "Experience growth through our comprehensive Data Science service! Leverage the power of data to drive innovation. Gain insights, identify trends, and make data-driven decisions. Scalable, secure, customizable – tailored to your needs. Build predictive models, optimize campaigns, enhance customer experience – all within reach.",
            "Uncover hidden opportunities with our Data Science service. Advanced statistical and ML techniques analyze vast data, revealing valuable insights. Better decisions, cost reduction, and operational optimization – your recipe for success. Visualize data, run A/B tests, optimize workflows – master the art of efficiency.",
            "Embrace a future powered by data with our Data Science service. Deepen your understanding of customers, products, and markets. Stay ahead in the dynamic digital landscape. Gain a competitive edge and drive innovation. Elevate your organization to new heights with the power of data-driven solutions.",
        ],
        img_path: `${ds}`
    },
    {
        id: "da",
        title: "Data Analytics",
        description: [
            "Discover growth through our comprehensive Data Analytics service! Leverage data's power for innovation. Gain insights, spot trends, and drive data-driven decisions. Scalable, secure, customizable – tailored to your needs. Optimize marketing, analyze behavior, mitigate risks – all within your reach.",
            "Unleash the potential of data with our Data Analytics service. Advanced tools analyze vast data, revealing hidden insights. Make informed decisions, cut costs, and boost efficiency. Visualize data with dashboards and reports, understand customers, products, and markets – master data-driven success.",
            "Embrace a future fueled by data with our Data Analytics service. Gain a competitive edge and drive industry innovation. Leverage data's power to stay ahead in today's dynamic digital landscape. Empower your organization with data-driven solutions, leading you towards excellence.",
                  ],
        img_path: `${da}`
    },
    {
        id: "cs",
        title: "Cloud Services",
        description: [
            "Unlock innovation and growth with our comprehensive Cloud Services! Leverage the power of the cloud for agility and cost reduction. Access a wide range of cloud-based technologies and solutions tailored to your needs. Migrate applications, build new solutions, optimize infrastructure – all made easy.",
            "Optimize operations with our scalable, secure, and customizable Cloud Services. Say goodbye to costly hardware and IT overheads. Free up resources for reinvestment, boosting operational efficiency. Data storage, backup, disaster recovery – all in one comprehensive solution.",
            "Embrace the cloud's potential with our advanced Cloud Services. Achieve your business objectives with ease. Stay ahead in the evolving digital landscape with a competitive advantage. Empower your organization with cloud-driven success, and propel your growth to new heights.",
                   ],
        img_path: `${cs}`
    },
    {
        id: "bd",
        title: "Big Data",
        description: [
            "Unleash data-driven insights with our comprehensive Big Data Solutions! Analyze large data sets, identify patterns, and make informed decisions. Scalable, secure, customizable – tailor to your specific needs. Store, process, and analyze data effortlessly – achieve your goals with ease.",
            "Gain a competitive edge with advanced analytics and insights from our Big Data Solutions. Leverage big data technologies to optimize operations, cut costs, and drive success. Experience the power of data storage, processing, and management, along with intuitive analytics and visualization tools.",
            "Stay ahead in the evolving digital landscape with our Big Data Solutions. Unlock your data's potential and drive innovation in your industry. Embrace the future of data-driven success and propel your organization towards unparalleled growth.",
                 ],
        img_path: `${bd}`
    },
    {
        id: "cybersecurity",
        title: "Cyber Security",
        description: [            
            "At Radar Tech, we prioritize safeguarding your digital assets and sensitive information against ever-evolving cyber threats.", 
            "Our comprehensive Cybersecurity service is tailored to protect your organization from potential security breaches, data theft, and malicious attacks.",
            " With a team of seasoned experts and cutting-edge technologies, we offer a multi-layered defensestrategy that includes robust network security, advanced threat detection, real-time monitoring, and rapid incident response.", 
             "Our proactive approach helps identify vulnerabilities, assess risks, and implement customized security solutions to fortify your digital infrastructure.", 
             "Trust SecureGuard Cyber Solutions to shield your business from cyber risks, ensuring peace of mind while you focus on achieving your goals"            
            ],
        img_path: `${sc}`
    }
]