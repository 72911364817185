export const solutions_list = [
    {
        id: "erp",
        title: 'Enterprise Resource Planning (ERP)',
        description: [
        "Our company offers a comprehensive ERP service that helps streamline your business operations by integrating various functions such as finance, accounting, human resources, and supply chain management into a single platform. With our cloud-based ERP system, you can access the software anytime, anywhere, and from any device, eliminating the need for expensive hardware and software installations.",
        " Our ERP service is fully customizable and scalable to meet your specific business needs, allowing you to optimize your processes and improve your decision-making capabilities. Our team of experts will work with you to configure the system, provide training, and offer ongoing maintenance and support to ensure optimal system performance.",
        "By implementing our ERP service, your company can benefit from enhanced visibility, real-time insights, and improved collaboration across different departments, leading to increased efficiency, productivity, and profitability. Whether you are a small startup or a large enterprise, our ERP service is designed to help you stay competitive and grow your business in a rapidly evolving market."
    ],
        img_path: "../erp.png"
    },
    {
        id: "servicenow",
        title: "ServiceNow",
        description: [
        "Our company offers ServiceNow as a comprehensive cloud-based platform that enables organizations to automate and streamline their business processes. ServiceNow provides a wide range of capabilities, including IT service management, human resources management, customer service management, and more, all from a single, user-friendly interface.",
        "With our ServiceNow service, your organization can benefit from a scalable, secure, and reliable solution that helps you achieve your business objectives. Our ServiceNow service can be customized to meet your specific needs and requirements, providing you with a tailored solution that aligns with your business goals.",
        "One of the key benefits of our ServiceNow service is its ability to automate routine tasks and workflows, freeing up your team to focus on higher-value activities. With ServiceNow, you can improve productivity, reduce costs, and minimize errors by automating repetitive tasks and providing real-time visibility into your operations.",
        "Our ServiceNow service also offers advanced analytics capabilities that can help you gain insights into your operations and identify areas for improvement. By leveraging ServiceNow's powerful reporting and analytics tools, you can make data-driven decisions and optimize your processes for maximum efficiency.",
        "Overall, our ServiceNow service is a valuable tool for any organization looking to improve its operational efficiency, streamline workflows, and deliver better outcomes for its customers. With our ServiceNow service, you can access a comprehensive solution that enables you to achieve your business objectives and drive continuous improvement across your organization."
    ],
        img_path: ""
    },
    {
        id: "salesforce",
        title: "Salesforce",
        description: [
        // "Our company offers a comprehensive Salesforce service that helps businesses optimize their sales and customer relationship management processes. Our team of Salesforce experts is committed to delivering tailored solutions that meet our clients' specific needs, whether they are small startups or large enterprises.",
        // "Our Salesforce service is designed to streamline your sales pipeline, automate workflows, and enhance collaboration between different teams, including sales, marketing, and customer support. With our cloud-based Salesforce system, you can access the software anytime, anywhere, and from any device, enabling you to stay connected and responsive to your customers' needs.",
        "Our Salesforce service is fully customizable and scalable, allowing you to adapt to changing market conditions and business requirements. We offer a range of services, including Salesforce implementation, customization, integration, training, and ongoing support and maintenance.",
        "By implementing our Salesforce service, you can benefit from enhanced visibility, real-time insights, and improved collaboration, leading to increased sales, customer satisfaction, and retention. Our team is dedicated to ensuring that you get the most out of your Salesforce investment and achieve your business goals.",
        "Overall, our Salesforce service is a cost-effective and efficient way to manage your sales and customer relationships, providing you with a competitive advantage in today's fast-paced market."
    ],
        img_path: ""
    },
    {
        id: "staffing",
        title: "Staffing Solutions",
        description: [
                   "We connect top talent with the right opportunities, simplifying your recruitment process and ensuring a dynamic workforce.",

"Save time and resources as we meticulously identify, screen, and select candidates who align with your company's culture and values.",

"Partner with us to unleash your organization's full potential, as our personalized staffing services propel your business towards greater achievements."
        ],
        img_path: ""
    }
]