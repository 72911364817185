import { Fragment } from 'react'
import { Menu, Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { NavLink } from 'react-router-dom'

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

type RDropdownProps = {
    title: string,
    items: any[]
}

export const RDropdown = (props: RDropdownProps) => {
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>

                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5  px-3 py-2">
                    {props.title}
                    <ChevronDownIcon className="-mr-1 h-6 w-6" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-left shadow-lg bg-green-700">
                    <div className="py-1">
                        {props.items.map(item => {
                            return (
                                <Menu.Item>
                                    {({ active }) => (
                                        <NavLink
                                            to={item.href}
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-white',
                                                'block px-4 py-2 text-sm'
                                            )}
                                        >
                                            {item.name}
                                        </NavLink>
                                    )}
                                </Menu.Item>
                            )
                        })}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
