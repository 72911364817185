import { Header } from './header'
import { Footer } from './footer'
import { ScrollToTop } from './scroll-to-top'

export const Shell = (props: any) => {
    return (
        <>
            <Header />
            {props.children}
            <ScrollToTop />
            <Footer />
        </>
    )
}
