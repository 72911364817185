import { BoltIcon, GlobeAltIcon, ScaleIcon } from '@heroicons/react/24/outline'
import { Parallax } from "react-parallax";
import { Title } from "./utils";
import { useNavigate } from 'react-router-dom';

const features = [
    {
        name: 'AI Solutions',
        description:
            `AI service combines advanced machine learning algorithms with a user-friendly interface to help businesses automate tasks, make data-driven decisions, 
            and gain valuable insights. `,
        icon: "ai_01.svg",
        to: "/services/ai"
    },
    {
        name: 'Machine Learning',
        description:
            `Machine learning service is a powerful tool that helps businesses unlock the value of their data through advanced algorithms and user-friendly interface.`,
        icon: "ml.jpg",
        to: "/services/ml"
    },
    {
        name: 'Data Analytics',
        description:
            `Data Analytics service provides businesses with the insights they need to make informed decisions and drive growth.`,
        icon: "da.svg",
        to: "/services/da"
    },
    {
        name: 'Data Science',
        description:
            `Data Science service provides businesses with the expertise and technology they need to turn data into actionable insights.`,
        icon: "ds.svg",
        to: "/services/ds"
    },
    {
        name: 'Cloud Services',
        description:
            `Cloud service provides businesses with the security, scalability, and accessibility they need to stay ahead in today's digital landscape. `,
        icon: "cs.svg",
        to: "/services/cs"
    },
    {
        name: 'Big Data',
        description:
            `Big Data service provides businesses with the tools and expertise they need to harness the power of large and complex datasets.`,
        icon: "bd.svg",
        to: "/services/bd"
    },
]

export const Services = () => {

    const navigate = useNavigate();

    return (
        <>
            <div id="about" className="relative my-2 sm:my-16 py-4 sm:py-8">

                <div className="mx-auto max-w-7xl ">
                    <div id="services" className="relative  mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8">

                        <Title title="Our Services" />

                        <dl className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                            {features.map((feature) => (
                                <>
                                    <div className="bg-white rounded-lg shadow-md overflow-hidden"
                                        onClick={e => navigate(feature.to)}
                                    >
                                        <img
                                            src={`${feature.icon}`}
                                            alt="Card Image"
                                            className="w-full h-64 object-cover"
                                        />
                                        <div className="p-4 flex flex-col ">
                                            <h2 className="text-xl font-semibold mb-2 theme-text">{feature.name}</h2>
                                            <p className="text-gray-600">{feature.description}</p>
                                            <a href="#" className="block mt-4 theme-text hover:text-blue-800 font-semibold flex self-end">Read more <span aria-hidden="true">→</span></a>
                                        </div>
                                    </div>
                                    {/* <div className="card card-compact bg-base-100 shadow-xl transition-all duration-500 ease-in-out transform hover:scale-105 cursor-pointer">
                                        <figure><img className="" src={`${feature.icon}`} alt={feature.name} /></figure>
                                        <div className="card-body">
                                            <h2 className="card-title text-2xl">{feature.name}</h2>
                                            <p className="text-lg">{feature.description}</p>
                                        </div>
                                    </div> */}
                                </>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>

        </>

    )
}
