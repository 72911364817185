import { theme } from "./header"

export const Title = ({title}) => {
    return(
        <>
            <h1 className="text-4xl font-bold tracking-tight text-warm-gray-900 sm:text-5xl lg:text-6xl py-6" style={{color: theme}}>
                {title}
            </h1>
        </>
    )
}

export const Service = (props) => {

    const img = props.img ? props.img : "https://images.unsplash.com/photo-1498758536662-35b82cd15e29?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2102&q=80"
    return(
        <>
        <div className="relative bg-white">
                <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
                    <div className="lg:col-span-5 lg:-mr-8 xl:mr-0">
                        <img
                            className="w-full bg-gray-50"
                            src={img}
                            alt=""
                        />
                    </div>
                    <div className="p-4 lg:col-span-7 lg:px-0 xl:col-span-7">
                        <div className="mx-auto max-w-2xl lg:mx-0 px-2 sm:px-4">
                            <h1 style={{ color: theme }} className=" mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                {props.title}
                            </h1>
                            {props.data.map((item, index) =>  <p key={index} className="mt-4 text-lg text-gray-500">{item}</p>)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}