import React, { useEffect, useState } from "react";
import { ArrowUpCircleIcon } from "@heroicons/react/24/solid";
import { theme } from "./header";

export const ScrollToTop = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 150) {
            setVisible(true)
        }
        else if (scrolled <= 150) {
            setVisible(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible);

        return () => window.removeEventListener("scroll", toggleVisible)
    }, []);



    return (

        <div className="fixed bottom-0 right-10 m-6 rounded-full hover:shadow-xl cursor-pointer z-999"
            style={{ backgroundColor: "transparent", display: visible ? "inline" : "none", transition: "all 0.5s ease", padding: "10px 13px" }}
            onClick={e => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }}
        >
            <ArrowUpCircleIcon color={theme} className="h-16 w-16" />
        </div>
    )
}