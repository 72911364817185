

import { theme } from "../header"
import { Shell } from "../shell"

type TeaserProps = {
    title: string,
    description: string[],
    img_path: string
}

export const Teaser = (props: TeaserProps) => {

    const img = props.img_path ? props.img_path : "https://images.unsplash.com/photo-1498758536662-35b82cd15e29?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2102&q=80"

    return (
        <Shell>
            <div className="relative bg-white">
                <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8 sm:my-8">
                    <div className="p-4 lg:col-span-7 lg:px-0 xl:col-span-7">
                        <div className="mx-auto max-w-2xl lg:mx-0 px-2 sm:px-4">
                            <h1 className=" mt-6 text-4xl font-bold tracking-tight theme-text sm:text-6xl">
                                {props.title}
                            </h1>
                            {props.description.map((item, index) => <p key={index} className="mt-4 text-lg text-gray-500">{item}</p>)}
                        </div>
                    </div>
                    <div className="lg:col-span-5 lg:-mr-8 xl:mr-0">
                        <img
                            className="w-full bg-gray-50 sm:mt-12"
                            src={img}
                            alt=""
                        />
                    </div>

                </div>
            </div>
        </Shell>
    )
}