import React from "react";
import { Header } from "./header";
import { HeroBanner } from "./hero-banner";
import { WhoWeAre } from "./who-we-are";
import { Services } from "./services";
import { Team } from "./team";
import { ContactUs } from "./contact-us";
import { ScrollToTop } from "./scroll-to-top";
import { Footer } from "./footer";
import {Partners} from "./partners";


export const Home = (props: any) => {

    return (
        <div className="App">
            <Header />
            <HeroBanner />
            <WhoWeAre />
            <Services />
            <Partners />
            {/* <Team /> */}
            <ContactUs />
            <ScrollToTop />
            <Footer />
        </div>
    )
}