import React from "react";
import { Shell } from "../components/shell";
import { theme } from "../components/header";

const about = [
    " Welcome to Radar Technosoft India Pvt Ltd!",

    "Established on 16th May 2012 and headquartered in Bangalore, Radar Technosoft is a leading IT services and product development company with a rich experience spanning over a decade. Our core expertise lies in offering a comprehensive range of services, including IT solutions, talent acquisition, and staff augmentation.",

    "At Radar Technosoft, we take pride in providing our clients across India with tailored IT opportunities that precisely meet their unique requirements. Our team of highly skilled professionals is dedicated to delivering cutting-edge solutions that drive success and foster growth for our esteemed clients.",

    " With a customer-centric approach, we believe in building strong and lasting partnerships. Our commitment to excellence and innovation has earned us the trust of numerous clients from various industries. We are driven by the desire to transform challenges into opportunities and to empower businesses through technology.",

    "Our key offerings include:",

    " 1. IT Services: We offer a comprehensive suite of IT services, including software development, web application development, mobile app development, cloud computing, and IT consulting. Our agile methodologies and domain expertise ensure that we deliver solutions that align perfectly with our clients' business objectives.",


    "2. Product Development: Radar Technosoft is at the forefront of creating cutting-edge software products that revolutionize industries. With a focus on innovation and user experience, we bring to life unique and robust products that add value to our clients' businesses.",

    "3. Talent Acquisition: Our talent acquisition team understands the importance of hiring the right people for the right roles. We connect businesses with skilled and talented professionals who can contribute significantly to their success.",

    "4. Staff Augmentation: When businesses need additional resources for short-term or long-term projects, our staff augmentation services come to the rescue. We provide competent professionals who seamlessly integrate with our clients' teams and contribute to their projects' success.",


    "Radar Technosoft takes pride in its strong commitment to quality, integrity, and customer satisfaction. Our success is driven by the success of our clients, and we continually strive to exceed expectations and deliver value.",

    " Join us on our journey of innovation and growth as we pave the way for a brighter, technology-driven future.",

    "Contact us today to explore how Radar Technosoft can be your strategic IT partner, empowering you to achieve your business goals."
]

export const AboutUs = (props: any) => {
    const img = props.img_path ? props.img_path : "https://images.unsplash.com/photo-1498758536662-35b82cd15e29?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2102&q=80"

    return (
        <div>
            <Shell>
                <div className="relative bg-white">
                    <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
                        <div className="lg:col-span-5 lg:-mr-8 xl:mr-0">
                            <img
                                className="w-full bg-gray-50 sm:mt-12"
                                src={img}
                                alt=""
                            />
                        </div>
                        <div className="p-4 lg:col-span-7 lg:px-0 xl:col-span-7">
                            <div className="mx-auto max-w-2xl lg:mx-0 px-2 sm:px-4">
                                <h1 style={{ color: theme }} className="mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                    About Radar Technosoft
                                </h1>
                                {about.map((item, index) => <p key={index} className="mt-4 text-lg text-gray-500">{item}</p>)}
                            </div>
                        </div>
                    </div>
                </div>
            </Shell>
        </div>
    )
}
