import { Fragment, useEffect, useState } from 'react'
import { Menu, Popover, Transition } from '@headlessui/react'
import {
    ArrowPathIcon,
    Bars3Icon,
    BookmarkSquareIcon,
    CalendarIcon,
    LifebuoyIcon,
    ShieldCheckIcon,
    Squares2X2Icon,
    XMarkIcon,
    HomeIcon,
    UserGroupIcon,
    PhoneIcon,
    ChatBubbleLeftIcon,
    AdjustmentsVerticalIcon,
    RectangleGroupIcon,
    BuildingLibraryIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Link, NavLink } from 'react-router-dom'
import { useWindowScroll } from 'react-use';

import logo from "../resources/logos/logo.png"
import logo1 from "../resources/logos/logo1.png"

import { RDropdown } from './dropdown'

export const theme = "#4AA848"

const solutions = [
    {
        id: "erp",
        name: 'Enterprise Resource Planning (ERP)',
        description: 'Streamline Your Business Operations with ERP.',
        href: '/solutions/erp',
        icon: Squares2X2Icon,
    },
    {
        name: 'ServiceNow',
        description: 'Accelerate Your Business with ServiceNow Solutions.',
        href: '/solutions/servicenow',
        icon: Squares2X2Icon,
    },
    {
        name: 'Salesforce',
        description: "Connect with your customers like never before with Salesforce.",
        href: '/solutions/salesforce',
        icon: Squares2X2Icon,
    },
    {
        name: 'Staffing Solutions',
        description: "Unlocking Your Potential: Tailored Staffing Solutions for Success",
        href: '/solutions/staffing',
        icon: Squares2X2Icon,
    }
]

const services = [
    {
        id: "ai",
        name: 'Artificial Intelligence (AI)',
        description: 'Leverage the power of AI to drive business success.',
        href: '/services/ai',
        icon: Squares2X2Icon
    },
    {
        id: "ml",
        name: 'Machine Learning (ML)',
        description: 'Unlocking Insights and Advancing Innovation through Customizable Machine Learning Solutions.',
        href: '/services/ml',
        icon: Squares2X2Icon
    },
    {
        id: "ds",
        name: 'Data Science',
        description: "Comprehensive Data Science Solutions for Business and Research.",
        href: '/services/ds',
        icon: Squares2X2Icon
    },
    {
        id: "da",
        name: 'Data Analytics',
        description: "Advanced Analytics Services for Informed Decision-Making and Competitive Advantage.",
        href: '/services/da',
        icon: Squares2X2Icon
    },
    {
        id: "cs",
        name: 'Cloud Services',
        description: "Enabling Agility and Business Growth in a Digital World.",
        href: '/services/cs',
        icon: Squares2X2Icon
    },
    {
        id: "bd",
        name: 'Big Data',
        description: "Comprehensive Solutions for Managing, Processing, and Analyzing Complex Data Sets.",
        href: '/services/bd',
        icon: Squares2X2Icon
    },
    {
        id: "ss",
        name: 'Cyber Security',
        description: "Securing Your Digital Frontier: Our Cybersecurity Services",
        href: '/services/cybersecurity',
        icon: Squares2X2Icon
    }
]

const Industries = [
    {
        name: 'Government ',
        description: 'Get all of your questions answered in our forums or contact support.',
        href: '/404',
        icon: LifebuoyIcon,
    },
    {
        name: 'Healthcare & Life Science',
        description: 'Learn how to maximize our platform to get the most out of it.',
        href: '/404',
        icon: LifebuoyIcon,
    },
    {
        name: 'Retail',
        description: 'See what meet-ups and other events we might be planning near you.',
        href: '/404',
        icon: LifebuoyIcon,
    },
    {
        name: 'Engineering ',
        description: 'Understand how we take your privacy seriously.',
        href: '/404',
        icon: LifebuoyIcon
    },
    {
        name: 'ECommerce ',
        description: 'Understand how we take your privacy seriously.',
        href: '/404',
        icon: LifebuoyIcon
    },
    {
        name: 'Banking and Financial',
        description: 'Understand how we take your privacy seriously.',
        href: '/404',
        icon: LifebuoyIcon
    },
    {
        name: 'Travel and Logistics',
        description: 'Understand how we take your privacy seriously.',
        href: '/404',
        icon: LifebuoyIcon
    },
    {
        name: 'Service Industry',
        description: 'Understand how we take your privacy seriously.',
        href: '/404',
        icon: LifebuoyIcon
    },
    {
        name: 'Agri Manufacturing',
        description: 'Understand how we take your privacy seriously.',
        href: '/404',
        icon: LifebuoyIcon
    }
]


function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export const Header = () => {

    //make this component as sticky nav bar
    const [isSticky, setSticky] = useState(false);
    const handleScroll = () => {
        if (window.pageYOffset > 0) {
            setSticky(true);
        } else if (window.pageYOffset <= 0) {
            setSticky(false);
        }
    }
    const { x, y } = useWindowScroll();

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true }); //add the event listener for when the user scrolls
        return () => {
            window.removeEventListener('scroll', handleScroll); //remove the event listener when the component is unmounted
        };
    }, []);

    const [isOpen, setIsOpen] = useState(false)


    return (

        <Popover
            data-wow-duration={`${isSticky}? 1s : 2s`}
            data-wow-delay={`${isSticky}? 2s : 4s`}
            // className={"ease-in-out duration-1000 sticky top-0 z-50 "}
            className={classNames(isSticky ? "sticky top-0 z-50" : "", "ease-in-out duration-1000 ")}
            style={{ backgroundColor: `${isSticky ? theme : "#E4ECEE"}` }}
        >

            <div className={"mx-auto max-w-7xl px-6"}>
                <div className="flex items-center justify-between py-4 justify-start space-x-10">
                    <div className="flex justify-start lg:flex-1">
                        <NavLink to={"/"}>
                            <span className="sr-only">Radar Tech</span>
                            {isSticky ?
                                <img className="w-auto h-12 sm:h-20" src={`${logo1}`} alt="Radar Tec" />
                                : <img className="w-auto h-12 sm:h-20" src={`${logo}`} alt="Radar Tec" />}
                        </NavLink>
                    </div>
                    <div className="-my-2 -mr-2 md:hidden">
                        <Popover.Button style={{ color: `${!isSticky ? theme : "white"}` }} className="inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                            <span className="sr-only">Open menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                    </div>

                    <Popover.Group as="nav" className="hidden space-x-10 md:flex z-999">
                        <NavLink to={"/"} className="flex flex-row text-base text-lg font-bold text-white text-gray-500 hover:text-gray-400" style={{ color: !isSticky ? theme : "#ffffff" }}>
                            <HomeIcon height={25} width={25} color={isSticky ? "ffffff" : theme} />
                            <span className="ml-1">Home</span>
                        </NavLink>
                        <Popover className="relative">
                            {({ open }) => (
                                <>
                                    <Popover.Button
                                        className={classNames(
                                            open ? 'text-white' : 'text-white',
                                            'group inline-flex font-bold items-center text-lg rounded-md hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-1'
                                        )}
                                        style={{ color: !isSticky ? theme : "#ffffff" }}
                                        onClick={() => setIsOpen(true)}
                                    >
                                        <AdjustmentsVerticalIcon height={25} width={25} color={isSticky ? "ffffff" : theme} />
                                        <span className="ml-2">Services</span>
                                        <ChevronDownIcon
                                            className={classNames(
                                                open ? 'text-white' : 'text-white',
                                                'ml-2 h-5 w-5 group-hover:text-gray-500'
                                            )}
                                            style={{ color: !isSticky ? theme : "#ffffff" }}
                                            aria-hidden="true"
                                        />
                                    </Popover.Button>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        {<Popover.Panel className="absolute z-10 -ml-4 mt-3 bg-white rounded-lg w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                                <div className="relative grid gap-6 px-5 py-6 sm:gap-8 sm:p-8">
                                                    {services.map((item) => (
                                                        <Link
                                                            key={item.name}
                                                            to={item.href}
                                                            onClick={e => setIsOpen(false)}
                                                            className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                                                        >
                                                            <item.icon className="h-6 w-6 flex-shrink-0" style={{ color: theme }} aria-hidden="true" />
                                                            <div className="ml-4">
                                                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                                                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                                            </div>
                                                        </Link>
                                                    ))}
                                                </div>

                                            </div>
                                        </Popover.Panel>}
                                    </Transition>
                                </>
                            )}
                        </Popover>
                        <Popover className="relative">
                            {({ open }) => (
                                <>
                                    <Popover.Button
                                        className={classNames(
                                            open ? 'text-white' : 'text-white',
                                            'group inline-flex font-bold items-center text-lg rounded-md hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-1'
                                        )}
                                        style={{ color: !isSticky ? theme : "#ffffff" }}
                                    >
                                        <RectangleGroupIcon height={25} width={25} color={isSticky ? "ffffff" : theme} />
                                        <span className="ml-2">Solutions</span>
                                        <ChevronDownIcon
                                            className={classNames(
                                                open ? 'text-white' : 'text-white',
                                                'ml-2 h-5 w-5 group-hover:text-gray-500'
                                            )}
                                            style={{ color: !isSticky ? theme : "#ffffff" }}
                                            aria-hidden="true"
                                        />
                                    </Popover.Button>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel className="absolute bg-white rounded-lg z-10 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                                            <div className="overflow-hidden rounded-lg shadow-lg ">
                                                <div className="relative grid gap-6 px-5 py-6 sm:gap-8 sm:p-8">
                                                    {solutions.map((item) => (
                                                        <NavLink
                                                            key={item.name}
                                                            to={item.href}
                                                            className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                                                        >
                                                            <item.icon className="h-6 w-6 flex-shrink-0" style={{ color: theme }} aria-hidden="true" />
                                                            <div className="ml-4">
                                                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                                                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                                            </div>
                                                        </NavLink>
                                                    ))}
                                                </div>

                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </>
                            )}
                        </Popover>
                        <Popover className="relative">
                            {({ open }) => (
                                <>
                                    <Popover.Button
                                        className={classNames(
                                            open ? 'text-white' : 'text-white',
                                            'group inline-flex font-bold items-center text-lg rounded-md hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-1'
                                        )}
                                        style={{ color: !isSticky ? theme : "#ffffff" }}
                                        onClick={() => setIsOpen(true)}
                                    >
                                        <BuildingLibraryIcon height={25} width={25} color={isSticky ? "ffffff" : theme} />
                                        <span className="ml-2">Industries</span>
                                        <ChevronDownIcon
                                            className={classNames(
                                                open ? 'text-white' : 'text-white',
                                                'ml-2 h-5 w-5 group-hover:text-gray-500'
                                            )}
                                            style={{ color: !isSticky ? theme : "#ffffff" }}
                                            aria-hidden="true"
                                        />
                                    </Popover.Button>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        {<Popover.Panel className="absolute z-10 -ml-4 mt-3 bg-white rounded-lg w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                                            <div className="overflow-auto rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                                <div className="relative grid gap-4 px-5 py-6 ">
                                                    {Industries.map((item) => (
                                                        <Link
                                                            key={item.name}
                                                            to={item.href}
                                                            onClick={e => setIsOpen(false)}
                                                            className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                                                        >
                                                            <item.icon className="h-6 w-6 flex-shrink-0" style={{ color: theme }} aria-hidden="true" />
                                                            <div className="ml-4">
                                                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                                                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                                            </div>
                                                        </Link>
                                                    ))}
                                                </div>

                                            </div>
                                        </Popover.Panel>}
                                    </Transition>
                                </>
                            )}
                        </Popover>
                        {/* <NavLink to={"/team"} className="flex flex-row text-base text-lg font-bold text-white text-gray-500 hover:text-gray-400" style={{ color: !isSticky ? theme : "#ffffff" }}>
                            <UserGroupIcon height={25} width={25} color={isSticky ? "ffffff" : theme} />
                            <span className="ml-1">Team</span>

                        </NavLink> */}
                        <NavLink to={"/about"} className="flex flex-row text-base text-lg font-bold text-white text-gray-500 hover:text-gray-400" style={{ color: !isSticky ? theme : "#ffffff" }}>
                            <ChatBubbleLeftIcon height={25} width={25} color={isSticky ? "ffffff" : theme} />
                            <span className="ml-1">About Us</span>
                        </NavLink>
                        <NavLink to={"/contact"} className="flex flex-row text-base text-lg font-bold text-white text-gray-500 hover:text-gray-400" style={{ color: !isSticky ? theme : "#ffffff" }}>
                            <PhoneIcon height={23} width={23} color={isSticky ? "ffffff" : theme} />
                            <span className="ml-1">Contact Us</span>
                        </NavLink>
                    </Popover.Group>
                </div>
            </div>

            <Transition
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Popover.Panel focus className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition bg-white md:hidden z-50 sm:z-auto">
                    <div className="divide-y-2 divide-gray-50 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="px-5 pt-5 pb-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <img
                                        className="h-16 w-auto"
                                        src={`${logo}`}
                                        alt="Radar Tech"
                                    />
                                </div>
                                <div className="-mr-2">
                                    <Popover.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span className="sr-only">Close menu</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className="mt-6">
                                <nav className="grid gap-y-4" style={{ color: theme }}>
                                    <NavLink to={"/"} className="inline-flex w-full  gap-x-1.5  px-3 py-2">
                                        <HomeIcon height={25} width={25} />
                                        <span className="ml-1">Home</span>
                                    </NavLink>
                                    <div className='flex flex-row items-center px-3'>
                                        <AdjustmentsVerticalIcon height={25} width={25} color={isSticky ? "ffffff" : theme} />
                                        <RDropdown title={'Services'} items={services} />
                                    </div>
                                    <div className='flex flex-row items-center px-3 '>
                                        <RectangleGroupIcon height={25} width={25} color={isSticky ? "ffffff" : theme} />
                                        <RDropdown title={'Solutions'} items={solutions} />
                                    </div>
                                    <div className='flex flex-row items-center px-3'>
                                        <BuildingLibraryIcon height={25} width={25} color={isSticky ? "ffffff" : theme} />
                                        <RDropdown title={'Industries'} items={Industries} />
                                    </div>
                                    <NavLink to={"/about"} className="inline-flex w-full  gap-x-1.5  px-3 py-2">
                                        <ChatBubbleLeftIcon height={25} width={25} />
                                        <span className="ml-1">About Us</span>
                                    </NavLink>
                                    <NavLink to={"/contact"} className="inline-flex w-full  gap-x-1.5 px-3 py-2" >
                                        <PhoneIcon height={23} width={23} />
                                        <span className="ml-1">Contact Us</span>
                                    </NavLink>
                                </nav>
                            </div>
                        </div>

                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>

    )
}
