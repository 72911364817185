import { EnvelopeIcon, PhoneIcon, HomeIcon } from '@heroicons/react/24/outline'
import { Title } from "./utils";
import { theme } from './header';
import { useState } from "react";
import axios from "axios";


const offices = [
    { id: 1, city: 'Europe', address: ['Kornalijnhorst 161,', '2592 HV', 'The Hague., Netherlands'], phone: "+31 644 753 807" },
    { id: 2, city: 'Singapore', address: ['10 Buroh Street', '#08-06 West Connect Building', 'Singapore, ph: 96913449'], phone: "96913449" },
    { id: 3, city: 'Dubai', address: ['A 305 Obaidulla building', 'Al Qusais first, Dubai, UAE'], phone: "00971585867228" },
    { id: 4, city: 'United States', address: ['114 Cobble Lane', 'London N1 2EF'], phone: "" },
]

const india_offices = [
    { id: 1, city: 'Bangalore - Head Office', address: ['L149/A, 5th Main Road,', 'Sector 6, HSR Layout,', 'Bengaluru, Karnataka 560102'], phone: "+91 8026745555" },
    { id: 2, city: 'Pune', address: ['1264, Pulachi Wadi,', 'Deccan Corner,', 'Pune - 411 004'], phone: "+91 7406003434" },
    { id: 3, city: 'Chennai', address: ['95, Poes Rd,', 'Subbarayan Nagar, Teynampet, Chennai, Tamil Nadu 600018'], phone: "+91 7406003535" }
]

export const ContactUs = () => {

    const getCurrentDateTime = () => {
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, "0");
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const year = currentDate.getFullYear();
        const hours = String(currentDate.getHours()).padStart(2, "0");
        const minutes = String(currentDate.getMinutes()).padStart(2, "0");

        return `${day}/${month}/${year} ${hours}:${minutes}`;
    };

    const [formData, setFormData] = useState({
        fname: "",
        lname: "",
        email: "",
        phone_number: "",
        subject: "",
        message: "",
        time: getCurrentDateTime()
    });

    const handleChange = (key: string, value: string) => {
        setFormData({ ...formData, [key]: value })
    }

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        try {
            const response = await axios.post("/api/saveFormData", formData);
            console.log("Data added successfully:", response);
            // Perform any additional actions after successful submission
        } catch (error) {
            console.error("Error adding data:", error);
        }
    }
    return (
        <div id="contact" className="relative bg-white py-4 sm:py-8">
            <div className="mx-auto max-w-7xl"></div>
            <div id="" className="bg-white">
                <main className="overflow-hidden">
                    {/* Header */}
                    <div className="bg-warm-gray-50">
                        <div className="py-4">
                            <div className="relative z-10 mx-auto max-w-7xl pl-4 pr-8 sm:px-6 lg:px-8">
                                <Title title=" Get in touch" />
                                <p className="mt-6 max-w-3xl text-xl text-warm-gray-500">
                                    To learn more about how radar technology can benefit your organization, please contact us. Our team of experts is dedicated to providing
                                    cutting-edge solutions and unparalleled customer support. Fill out the form, or give us a call, and we'll be happy to answer
                                    any questions you may have. Let's work together to unlock the full potential of radar technology for your business.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Contact section */}
                    <section className="relative bg-white" aria-labelledby="contact-heading">
                        <div className="absolute h-1/2 w-full bg-warm-gray-50" aria-hidden="true" />
                        {/* Decorative dot pattern */}
                        <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
                            <svg
                                className="absolute top-0 right-0 z-0 -translate-y-16 translate-x-1/2 transform sm:translate-x-1/4 md:-translate-y-24 lg:-translate-y-72"
                                width={404}
                                height={384}
                                fill="none"
                                viewBox="0 0 404 384"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect x={0} y={0} width={4} height={4} className="text-warm-gray-200" fill="currentColor" />
                                    </pattern>
                                </defs>
                                <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
                            </svg>
                        </div>
                        <div className="mx-auto max-w-7xl px-6 lg:px-8">
                            <div className="relative bg-gray-100 shadow-xl">
                                <h2 id="contact-heading" className="sr-only">
                                    Contact us
                                </h2>

                                <div className="grid grid-cols-1 lg:grid-cols-3">
                                    {/* Contact information */}
                                    <div className="relative overflow-hidden py-10 px-6 sm:px-10 xl:p-12" style={{ backgroundColor: theme }}>
                                        {/* Decorative angle backgrounds */}
                                        <div className="pointer-events-none absolute inset-0 sm:hidden" aria-hidden="true">
                                            <svg
                                                className="absolute inset-0 h-full w-full"
                                                width={343}
                                                height={388}
                                                viewBox="0 0 343 388"
                                                fill="none"
                                                preserveAspectRatio="xMidYMid slice"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                                                    fill="url(#linear1)"
                                                    fillOpacity=".1"
                                                />
                                                <defs>
                                                    <linearGradient
                                                        id="linear1"
                                                        x1="254.553"
                                                        y1="107.554"
                                                        x2="961.66"
                                                        y2="814.66"
                                                        gradientUnits="userSpaceOnUse"
                                                    >
                                                        <stop stopColor="#fff" />
                                                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div
                                            className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 sm:block lg:hidden"
                                            aria-hidden="true"
                                        >
                                            <svg
                                                className="absolute inset-0 h-full w-full"
                                                width={359}
                                                height={339}
                                                viewBox="0 0 359 339"
                                                fill="none"
                                                preserveAspectRatio="xMidYMid slice"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                                                    fill="url(#linear2)"
                                                    fillOpacity=".1"
                                                />
                                                <defs>
                                                    <linearGradient
                                                        id="linear2"
                                                        x1="192.553"
                                                        y1="28.553"
                                                        x2="899.66"
                                                        y2="735.66"
                                                        gradientUnits="userSpaceOnUse"
                                                    >
                                                        <stop stopColor="#fff" />
                                                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div
                                            className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 lg:block"
                                            aria-hidden="true"
                                        >
                                            <svg
                                                className="absolute inset-0 h-full w-full"
                                                width={160}
                                                height={678}
                                                viewBox="0 0 160 678"
                                                fill="none"
                                                preserveAspectRatio="xMidYMid slice"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                                                    fill={`url(${theme})`}
                                                    fillOpacity=".1"
                                                />
                                                <defs>
                                                    <linearGradient
                                                        id="linear3"
                                                        x1="192.553"
                                                        y1="325.553"
                                                        x2="899.66"
                                                        y2="1032.66"
                                                        gradientUnits="userSpaceOnUse"
                                                    >
                                                        <stop stopColor="#fff" />
                                                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                        <h3 className="text-2xl font-medium text-white">Contact information</h3>
                                        <p className="mt-6 max-w-3xl text-xl text-teal-50 text-fall">
                                            To learn more about how radar technology can benefit your organization, please contact us. Our team of experts is dedicated to providing
                                            cutting-edge solutions and unparalleled customer support. Fill out the form, or give us a call, and we'll be happy to answer
                                            any questions you may have. Let's work together to unlock the full potential of radar technology for your business.
                                        </p>
                                        <dl className="mt-8 space-y-6">
                                            <dt>
                                                <span className="sr-only">Phone number</span>
                                            </dt>
                                            <dd className="flex text-base text-teal-50">
                                                <PhoneIcon className="h-6 w-6 flex-shrink-0 text-teal-200" aria-hidden="true" />
                                                <span className="ml-3">(080) 2674 5555</span>
                                            </dd>
                                            <dt>
                                                <span className="sr-only">Email</span>
                                            </dt>
                                            <dd className="flex text-base text-teal-50">
                                                <EnvelopeIcon className="h-6 w-6 flex-shrink-0 text-teal-200" aria-hidden="true" />
                                                <span className="ml-3">HR@radartec.com</span>
                                            </dd>
                                        </dl>
                                        <ul role="list" className="mt-8 flex space-x-12">
                                            <li>
                                                <a className="text-teal-200 hover:text-teal-100" href="#">
                                                    <span className="sr-only">Facebook</span>
                                                    <svg className="h-7 w-7" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="text-teal-200 hover:text-teal-100" href="#">
                                                    <span className="sr-only">GitHub</span>
                                                    <svg className="h-7 w-7" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="text-teal-200 hover:text-teal-100" href="#">
                                                    <span className="sr-only">Twitter</span>
                                                    <svg className="h-7 w-7" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                                                        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                                                    </svg>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    {/* Contact form */}
                                    <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                                        <h3 className="text-lg font-medium text-warm-gray-900">Send us a message</h3>
                                        <form className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                                            <div>
                                                <label htmlFor="first-name" className="block text-sm font-medium text-warm-gray-900">
                                                    First name
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        value={formData.fname}
                                                        onChange={(e) => handleChange("fname", e.target.value)}
                                                        type="text"
                                                        name="fname"
                                                        id="first-name"
                                                        autoComplete="given-name"
                                                        className="block w-full rounded-md border-warm-gray-300 py-3 px-4 text-warm-gray-900 shadow-sm focus:border-teal-500 focus:ring-teal-500"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="last-name" className="block text-sm font-medium text-warm-gray-900">
                                                    Last name
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        value={formData.lname}
                                                        onChange={(e) => handleChange("lname", e.target.value)}
                                                        type="text"
                                                        name="lname"
                                                        id="last-name"
                                                        autoComplete="family-name"
                                                        className="block w-full rounded-md border-warm-gray-300 py-3 px-4 text-warm-gray-900 shadow-sm focus:border-teal-500 focus:ring-teal-500"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="email" className="block text-sm font-medium text-warm-gray-900">
                                                    Email
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        value={formData.email}
                                                        onChange={(e) => handleChange("email", e.target.value)}
                                                        id="email"
                                                        name="email"
                                                        type="email"
                                                        autoComplete="email"
                                                        className="block w-full rounded-md border-warm-gray-300 py-3 px-4 text-warm-gray-900 shadow-sm focus:border-teal-500 focus:ring-teal-500"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="flex justify-between">
                                                    <label htmlFor="phone" className="block text-sm font-medium text-warm-gray-900">
                                                        Phone
                                                    </label>
                                                </div>
                                                <div className="mt-1">
                                                    <input
                                                        value={formData.phone_number}
                                                        onChange={(e) => handleChange("phone_number", e.target.value)}
                                                        type="text"
                                                        name="phone_number"
                                                        id="phone"
                                                        autoComplete="tel"
                                                        className="block w-full rounded-md border-warm-gray-300 py-3 px-4 text-warm-gray-900 shadow-sm focus:border-teal-500 focus:ring-teal-500"
                                                        aria-describedby="phone-optional"
                                                    />
                                                </div>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <label htmlFor="subject" className="block text-sm font-medium text-warm-gray-900">
                                                    Subject
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        value={formData.subject}
                                                        onChange={(e) => handleChange("subject", e.target.value)}
                                                        type="text"
                                                        name="subject"
                                                        id="subject"
                                                        className="block w-full rounded-md border-warm-gray-300 py-3 px-4 text-warm-gray-900 shadow-sm focus:border-teal-500 focus:ring-teal-500"
                                                    />
                                                </div>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <div className="flex justify-between">
                                                    <label htmlFor="message" className="block text-sm font-medium text-warm-gray-900">
                                                        Message
                                                    </label>
                                                    <span id="message-max" className="text-sm text-warm-gray-500">
                                                        Max. 500 characters
                                                    </span>
                                                </div>
                                                <div className="mt-1">
                                                    <textarea
                                                        value={formData.message}
                                                        onChange={(e) => handleChange("message", e.target.value)}
                                                        id="message"
                                                        name="message"
                                                        rows={4}
                                                        className="block w-full rounded-md border-warm-gray-300 py-3 px-4 text-warm-gray-900 shadow-sm focus:border-teal-500 focus:ring-teal-500"
                                                        aria-describedby="message-max"
                                                        defaultValue={''}
                                                    />
                                                </div>
                                            </div>
                                            <div className="sm:col-span-2 sm:flex sm:justify-end">
                                                <button
                                                    type="button"
                                                    className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-teal-500 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-teal-600 focus:outline-none focus:ring-2 sm:w-auto"
                                                    style={{ backgroundColor: theme }}
                                                    onClick={handleSubmit}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Contact grid */}
                    <section aria-labelledby="offices-heading">
                        <div className="mx-auto max-w-7xl py-12 px-6 lg:px-8">
                            <h2 id="offices-heading" className="text-3xl font-bold tracking-tight text-warm-gray-900">
                                Our offices
                            </h2>
                            <p className="mt-6 text-lg text-warm-gray-500">
                                Our company has a global network of offices, providing localized support and services to clients around the world.
                                Our offices are located in key cities including United States, Europe, Singapore, Dubai, and more. This global reach allows us to bring
                                diverse perspectives and cutting-edge technology to the table, ensuring the best solutions and outcomes for our clients.
                            </p>

                            <div className="mt-10 grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-4">
                                {india_offices.map((office) => (
                                    <div className="" key={office.id}>
                                        <h3 className="text-lg font-medium text-warm-gray-900">{office.city}</h3>
                                        <div className="flex mt-2">
                                            <div className="flex-shrink-0">
                                                <HomeIcon className="h-6 w-6 text-warm-gray-500" aria-hidden="true" />
                                            </div>
                                            <div className="ml-3 text-base text-warm-gray-500">
                                                {office.address.map((line) => (
                                                    <span key={line} className="block">{line}</span>
                                                ))}
                                            </div>
                                        </div>


                                        <div className="flex mt-1">
                                            <div className="flex-shrink-0">
                                                <PhoneIcon className="h-6 w-6 text-warm-gray-500" aria-hidden="true" />
                                            </div>
                                            <div className="ml-3 text-base text-warm-gray-500">
                                                {office.phone}
                                            </div>
                                        </div>

                                    </div>
                                ))}
                            </div>
                            <div className="mt-10 grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-4">
                                {offices.map((office) => (
                                    <div key={office.id}>
                                        <h3 className="text-lg font-medium text-warm-gray-900">{office.city}</h3>
                                        <div className="flex mt-2">
                                            <div className="flex-shrink-0">
                                                <HomeIcon className="h-6 w-6 text-warm-gray-500" aria-hidden="true" />
                                            </div>
                                            <div className="ml-3 text-base text-warm-gray-500">
                                                {office.address.map((line) => (
                                                    <span key={line} className="block">{line}</span>
                                                ))}
                                            </div>
                                        </div>


                                        <div className="flex mt-1">
                                            <div className="flex-shrink-0">
                                                <PhoneIcon className="h-6 w-6 text-warm-gray-500" aria-hidden="true" />
                                            </div>
                                            <div className="ml-3 text-base text-warm-gray-500">
                                                {office.phone}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    )
}
