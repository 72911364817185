import React from 'react'
import { useWindowSize } from 'react-use';
import { Header, theme } from './header';
import { Pparticles } from "./particle";
import { url } from 'inspector';

export const HeroBanner = () => {

    const { height, width } = useWindowSize();
    const isMobile = width <= 718;
    // @ts-ignore
    return (
        <>
            <div id="home" className="relative isolate overflow-hidden hero-bg-image" style={{ minHeight: height }}>
                {/* <div className="absolute" style={{ zIndex: -1 }}>
                    <Pparticles />
                </div> */}
                <div className="px-6 lg:px-8 relative mx-auto max-w-7xl " >
                    <div className="mx-auto max-w-2xl py-24 sm:py-28 lg:py-24">
                        <div className="text-center">
                            <h1
                                className="text-4xl font-bold tracking-tight sm:text-8xl wow animate__slideInDown"
                                data-wow-duration="5s"
                                style={{ color: theme }}
                            >
                                Intelligent Solutions for a Smarter World
                            </h1>
                            <p
                                style={{ color: theme }}
                                className="mt-6 text-2xl leading-8 wow animate__slideInUp" data-wow-duration="5s">
                                Leverage the power of AI and Machine Learning to drive business success. Our team of experts provides
                                cutting-edge solutions and unparalleled service to help you stay ahead in a rapidly changing market.
                            </p>
                            <div className="mt-10 flex items-center justify-center gap-x-6">
                                <a
                                    href="#"
                                    style={{ backgroundColor: theme }}
                                    className="rounded-md px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-emerald-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                                >
                                    Get started
                                </a>
                                <a href="#" style={{ color: theme }} className="text-base font-semibold leading-7 text-white">
                                    Learn more <span aria-hidden="true">→</span>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
