import { theme } from "./header";

export const Partners = () => {


    return (
        <>
            <div className="max-w-7xl mx-auto pt-4 ">
                <div className="w-11/12 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto sm:mb-10 mb-16">
                    <h1 className=" xl:text-5xl md:text-3xl text-xl text-center font-extrabold mb-5 pt-4" style={{ color: theme }}>Partnerships with Coveted Brands</h1>
                    <p className="text-base md:text-lg lg:text-xl text-center text-gray-600 font-normal xl:w-10/12 xl:mx-auto">Our success has come from being committed to the property and investing in the development of the product to maximize sales. At the same time and maintaining the integrity.</p>
                </div>

                <div className="py-8 px-15 flex items-center justify-between flex-wrap">
                    <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:pb-10 pb-16 items-center px-2">
                        <img src="/partners/tm.png" alt={""} />
                    </div>
                    <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:pb-10 pb-16 items-center px-2">
                        <img src="/partners/oracle.png" alt={""} />
                    </div>
                    <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:pb-10 pb-16 pt-4 items-center px-2">
                        <img src="/partners/Genpact.png" alt={""} />
                    </div>
                    <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:pb-10 pb-16 items-center px-2">
                        <img src="/partners/Cognizant.png" alt={""} />
                    </div>
                    <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:pt-10 items-center px-2">
                        <img src="/partners/coforge.png" alt={""} />
                    </div>
                    <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:pt-10 items-center px-2">
                        <img src="/partners/Capita.png" alt={""} />
                    </div>
                    <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:pt-10 lg:pt-10 md:pt-2 pt-16 px-2">
                        <img src="/partners/AnvationLabs.png" alt={""} />
                    </div>
                    <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center xl:pt-10 lg:pt-10 md:pt-2 pt-16 px-2">
                        <img src="/partners/VeloVeritas.png" alt={""} />
                    </div>
                    <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center  xl:pb-10 pb-16 pt-4 items-center px-2">
                        <img className="h-40 w-40" src="/partners/Rajneethi.png" alt={""} />
                    </div>
                    <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center  xl:pb-10 pb-16 pt-4 items-center px-2">
                        <img className="" src="/partners/infinitylogo.png" alt={""} />
                    </div>
                    <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center  xl:pb-10 pb-16 pt-4 items-center px-2 ">
                        <img className="" src="/partners/iOpexTechnologiesLogo.png" alt={""} />
                    </div>
                    <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center  xl:pb-10 pb-16 pt-4 items-center px-2">
                        <img className="" src="/partners/atossyntel.png" alt={""} />
                    </div>
                    <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center  xl:pb-10 pb-16 pt-4 items-center px-2">
                        <img className="" src="/partners/Harman.png" alt={""} />
                    </div>
                    <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center  xl:pb-10 pb-16 pt-4 items-center px-2">
                        <img className="" src="/partners/svp.png" alt={""} />
                    </div>
                    <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center  xl:pb-10 pb-16 pt-4 items-center px-2">
                        <img className="" src="/partners/snt.png" alt={""} />
                    </div>
                    <div className="w-6/12 xl:w-1/4 lg:w-1/4 md:w-1/4 flex justify-center  xl:pb-10 pb-16 pt-4 items-center px-2">
                        <img className="" src="/partners/finboot.png" alt={""} />
                    </div>
                </div>
            </div>
        </>
    )
}

