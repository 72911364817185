import React from "react";
import { Shell } from "../components/shell";
import { Team } from "../components/team";


export const TeamDetails = () => {

    return (
        <div>
            <Shell>
                <Team />
            </Shell>
        </div>
    )
}
