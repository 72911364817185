import React from "react"
import { useParams } from "react-router-dom"
import { services_list } from "../components/services/services_list"
import { Teaser } from "../components/services/teaser";

export const RDServices = (props: any) => {

    const { key } = useParams();
    const service_obj = services_list.find(x => x.id === key);
    //@ts-ignore
    const { title, description, img_path } = service_obj;
    return (
        <>
            <Teaser
                description={description}
                title={title}
                img_path={img_path}
            />
        </>
    )
}

