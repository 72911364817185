import { useEffect, useState } from "react";
import { Title } from "./utils";
import { theme } from "./header";


export const WhoWeAre = () => {

    const [opclass, setOpclass] = useState("opacity-0");
    useEffect(() => {
        const x = setTimeout(function () {
            setOpclass("opacity-100")
        }, 2500);

        return () => {
            clearInterval(x)
        }
    }, []);

    return (
        <>
            <div id="who-we-are" className="relative bg-white">
                <div className="mx-auto max-w-7xl">
                    <div className="relative z-10 lg:w-full lg:max-w-2xl">
                        <svg
                            className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="none"
                            aria-hidden="true"
                        >
                            <polygon points="0,0 90,0 50,100 0,100" />
                        </svg>


                        <div className="relative py-32 px-6 sm:py-40 lg:py-56 lg:px-8 lg:pr-0">
                            <div className={`mx-auto max-w-2xl lg:mx-0 lg:max-w-xl transition-all duration-500 ease-in-out transform hover:scale-105`}>
                                <Title title="Who we Are!" />
                                <p className="mt-6 text-lg leading-8 text-gray-600">
                                    Welcome to a new era of data-driven decision-making. Our AI,
                                    machine learning, data analytics and data science software company is dedicated to empowering organizations
                                    with cutting-edge technology solutions. With our powerful tools, organizations can turn their data into actionable
                                    insights and drive growth like never before. Our mission is to simplify the complex and make data accessible to everyone.
                                    Join us on our journey to revolutionize the way the world works with data.
                                </p>
                                <a href="#" className="text-base text-emerald-600 font-semibold leading-7 self-end" color={theme}>
                                    Learn more <span aria-hidden="true">→</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                    <img
                        className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
                        src="ai.jpg"
                        alt=""
                    />
                </div>
            </div>
        </>
    )
}